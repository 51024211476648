import React from "react";
import Layout from "../layouts";
import background_image from "../images/projekte-ohiostrasse-10-6.jpg";
import leistung1 from "../images/istock-623209198-compressor.jpg";
import leistung2 from "../images/istock-970899460-compressor.jpg";
import leistung3 from "../images/istock-918746438-compressor.jpg";
import leistung4 from "../images/istock-655290350-compressor.jpg";
import leistung5 from "../images/istock-624617308-compressor.jpg";
import portrait from "../images/portrait-antje-abel.jpg";
import {Row, Col, Container} from "reactstrap";
import { useSwipeable } from "react-swipeable";
import {scrollElementIntoView} from "../components/helper";

function Buero(props) {
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
    function handleClick(type) {
        if (type === 'previous') {
            document.getElementById('card-container-master').firstChild.classList.add('left');
        } else {
            document.getElementById('card-container-master').firstChild.classList.add('right');
        }
        setTimeout(() => {
            document.getElementById('card-container-master').append(document.getElementById('card-container-master').firstChild);
        }, 250);
        setTimeout(() => {
            let elements = document.querySelectorAll(".card-container-container .card-container");
            for(let i = 0; i < elements.length; i++) {
                elements[i].classList.remove('left');
                elements[i].classList.remove('right');
            }
        }, 350);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => handleClick('previous'),
        onSwipedRight: () => handleClick('next'),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true,
        onTap: () => handleClick('next'),
    });
  return (
    <Layout theme="light" location={props.location} title="Antje Abel Architekten | Büro" description="Wir sind ein Architekturbüro mit Sitz in Karlsruhe und stehen unseren Bauherren während des gesamten Bauprozesses mit unserem Know-How zur Verfügung." keywords="Büro, " robots="index, follow">
      <div className="section image-header has-button" style={{backgroundImage: `url(${background_image})`}}>
        <div className="image-header-overlay" />
        <h1>Wir sind ein Architekturbüro mit Sitz in Karlsruhe und realisieren Bauprojekte von groß bis klein.</h1>
        <a onClick={() => {scrollElementIntoView(document.getElementById('text-element'))}}>Mehr erfahren</a>
      </div>
      <div className="section">
        <div className="text-container" id="text-element">
          <p>
              Wir sind ein Architekturbüro mit Sitz in Karlsruhe und stehen unseren Bauherren während des gesamten Bauprozesses mit unserem Know-How zur Verfügung. Wir sind davon überzeugt, dass gute Architektur eine dauerhafte positive Auswirkung auf unser Wohlbefinden, Gesundheit und Produktivität hat und arbeiten deshalb an der Entwicklung und Realisierung von Projekten, die Erwartungen übertreffen und einen langfristigen Mehrwert schaffen.
          </p>
        </div>
      </div>
      <Container className="reduced-width">
          <div className="section section-dark">
              <div className="text-container">
                  <h2 className="title">
                      Unsere Leistungen
                  </h2>
                  <p>
                      Unsere Leistungen umfassen die Planung und Realisierung von Hochbauten, das Entwickeln städtebaulicher Konzepte, das Interior Design.
                  </p>
              </div>
              <Container>
                  <Col>
                      <div className="card-stack">
                          <div className="cards-wrapper">
                              <div className="card-container-container" id="card-container-master" {...handlers}>
                                  <div className="card-container" id="1">
                                      <div className="occasion">
                                          <div className="img-container" style={{backgroundImage: `url(${leistung1})`}}/>
                                          <div className="content">
                                              <h3 className="card-title">Gebäudeplanung</h3>
                                              <p className="description">Wir planen Gebäude aller Art. Egal ob Einfamilienhaus oder Gewerbe- und Industriebau. Bei uns erhalten Sie professionelle Unterstützung für alle Phasen und Belange Ihrer individuellen Anliegen und persönlichen Vorhaben.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card-container" id="2">
                                      <div className="occasion">
                                          <div className="img-container" style={{backgroundImage: `url(${leistung2})`}}/>
                                          <div className="content">
                                              <h3 className="card-title">Ausschreibung & Vergabe</h3>
                                              <p className="description">Aufgrund von unserer langfristigen Erfahrung und bereits zahlreich realisierten Projekte, wissen wir, worauf es bei der Ausschreibung und Vergabe ankommt. Ergänzt wird diese Erfahrung durch innovative Softwarelösungen und ein starkes Partnernetzwerk.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card-container" id="3">
                                      <div className="occasion">
                                          <div className="img-container" style={{backgroundImage: `url(${leistung3})`}}/>
                                          <div className="content">
                                              <h3 className="card-title">Bauüberwachung</h3>
                                              <p className="description">Für die von uns geplanten Gebäude übernehmen wir die Bauüberwachung und stellen so sicher, dass die im Vorfeld ausgearbeiteten Planungen wie gewünscht umgesetzt werden, Prozesse reibungslos ablaufen und Arbeiten entsprechend dokumentiert werden.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card-container" id="4">
                                      <div className="occasion">
                                          <div className="img-container" style={{backgroundImage: `url(${leistung4})`}}/>
                                          <div className="content">
                                              <h3 className="card-title">Interior Design</h3>
                                              <p className="description">Neben klassischen Architekturdienstleistungen, stehen wir unseren Bauherren auch bei Fragen rund um die Innenarchitektur zur Verfügung. Dabei gleicht kein Interieur dem anderen, da jeder Auftrag eine individuelle Lösung mit sich bringt.</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card-container" id="5">
                                      <div className="occasion">
                                          <div className="img-container" style={{backgroundImage: `url(${leistung5})`}}/>
                                          <div className="content">
                                              <h3 className="card-title ">3D-Visualisierungen</h3>
                                              <p className="description">In der Entwurfs- und Planungsphase arbeiten wir intensiv im dreidimensionalen Modell. So können wir unseren Bauherren bereits im Vorfeld genau zeigen, wie ihr Gebäude später einmal aussehen wird und dieses bereits während der Planung erlebbar machen.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="navigation">
                                  <div
                                      className="navigation-buttons-container">
                                      <button className="navigation-button navigation-button--previous" onClick={() => {handleClick('previous')}}><div className="arrow-right"/></button>
                                      <button className="navigation-button navigation-button--next" onClick={() => {handleClick('next')}}><div className="arrow-right"/></button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </Col>
              </Container>
          </div>
      </Container>
      <div className="section">
        <div className="text-container">
            <h2 className="title">
                Unsere Philosophie
            </h2>
            <p>
                Unsere Herangehensweise ist ganzheitlich und zieht alle dem Projekt eigenen Charakteristika mit in Betracht. Denn nur so ist es uns möglich, einmalige Gebäude zu entwickeln, die ein Wow auslösen.
            </p>
        </div>
        <Container>
          <div className="image-text-container">
              <div className="img-container-wrapper">
                  <div className="img-container" style={{backgroundImage: `url(${portrait})`}}/>
              </div>
            <Row>
              <Col lg="6"/>
              <Col lg="6">
                  <div className="txt-container">
                      <p>
                          „Bei allen unseren Bauprojekten verfolgen wir das Ziel einer gesamthaften und durchgehenden Konzeption, die Nachhaltigkeit mit einer zeitlosen Architektursprache definiert und uneingeschränkt Innovation, Design, Wohlbefinden und Werterhaltung in die Aufgabenstellung miteinbezieht. Zusammen mit unseren Partnern, setzen wir diese Konzepte in bauliche Strukturen um und stellen dabei die Bedürfnisse und Anforderungen unserer Bauherren in den Mittelpunkt.“
                      </p>
                      <span className="name">
                            Antje Abel
                      </span>
                      <span className="subline">Architektin und Gründerin von AMA Architekten</span>
                  </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export default Buero;
