export const scrollElementIntoView = (element) => {
  let scrollTop = window.pageYOffset || element.scrollTop;

  let finalOffset = 0;
  if (window.innerWidth >= 1200) {
    finalOffset = element.getBoundingClientRect().top + scrollTop - 160;
  } else if (window.innerWidth >= 768 && window.innerWidth <= 1199) {
    finalOffset = element.getBoundingClientRect().top + scrollTop - 144;
  } else {
    finalOffset = element.getBoundingClientRect().top + scrollTop - 128;
  }

  window.parent.scrollTo({
    top: finalOffset,
    behavior: 'smooth'
  })
}
